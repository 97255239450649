import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import axios from "axios/index";
import Consts from "../core/consts";
import {id2finanzamt} from "./DE/finanzamter"
import {dateFormat, dateFormatString, dateFormatTime, DEPeriods, extractSteuernummer} from "./filing_support"
import {FaArrowCircleRight, FaDownload, FaUpload} from "react-icons/fa";
import {InsertIfNonClient} from "../core/input_fields";
import {UserContext} from "../user/UserContext";
import {downloadAsExcel} from "../core/download";
import {apiGet} from "../core/api";


if (!Object.entries)
    Object.entries = function (obj) {
        var ownProps = Object.keys(obj),
            i = ownProps.length,
            resArray = new Array(i); // preallocate the Array
        while (i--)
            resArray[i] = [ownProps[i], obj[ownProps[i]]];

        return resArray;
    };

class FilingDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            byCompany: window.location.pathname.indexOf("bycompany") > -1
        };
        this.delete = this.delete.bind(this)

    }

    apiGet = apiGet.bind(this);
    static contextType=UserContext;
    delete() {
        this.setState({loading: true});
        axios.get(Consts.API_PREFIX + "/vat_return/delete/" + this.props.filing.ID)
            .then(response => {
                if (response.data.status === "success") {
                    this.setState({
                        loading: false,
                    });
                    if (this.props.filing.status === 0) {
                        if (!this.props.t.state.filings.unsubmitted) {
                            const filings = this.props.t.state.filings.filter(a => a.ID !== this.props.filing.ID);
                            this.props.t.setState({
                                filings,
                                error: "",
                            })
                        } else {
                            const unsubmitted = this.props.t.state.filings.unsubmitted.filter(a => a.ID !== this.props.filing.ID);
                            this.props.t.setState({
                                filings: {submitted: this.props.t.state.filings.submitted, unsubmitted},
                                error: "",
                            })
                        }
                    }

                } else {
                    this.setState({loading: false});
                    this.props.t.setState({
                        error: response.data.message,
                    })
                }
            })
            .catch((e) => {
                this.setState({
                    loading: false,
                    error: e.message
                });
                if (this.props.t?.setState) {
                    this.props.t.setState({
                        error: e.message
                    })
                }
            });
    }


    render() {
        const colSpan = 1;
        const colAll = this.props.filing.status === 0 ? 6 : 4;
        var f = this.props.filing.data;
        //console.log(f, this.props.filing.status)
        /*
        <a href={"/filings"}>back</a>
                <br/><br/>
                <h1>Filing Details</h1>
                <br/>
         */
        delete f.reminders;

        const sort_indices = {
            "specific": 20,
            "company": 30,
            "lastSaved": 12,
            "user": 10,
            "submissionFiles": 100,
        };

        if (f.test_submits !== undefined && f.test_submits.length) {
            f.test_submits = {"testSubmit": f.test_submits[f.test_submits.length - 1]};
        }
        let ff = Object.entries(f);//Object.keys(f).map(a=>[a,f[a]])
        let d = ff.sort((a, b) => {
            const a_s = sort_indices[a[0]] !== undefined ? sort_indices[a[0]] : 1000;
            const b_s = sort_indices[b[0]] !== undefined ? sort_indices[b[0]] : 1000;
            return a_s < b_s ? -1 : 1
        });
        /*let xmlDoc;
        if (f.general.country === "DE" && f.response !== undefined) {
            let parser = new DOMParser();

            xmlDoc = parser.parseFromString(f.response, "text/html");
            //console.log("parser", parser, xmlDoc.querySelector("Rueckgabe Text"))
        }*/

        let skips = ["error", "status", "excel", "country", "report", "loading", "availCreds", "availCredsKey", "loadedMain",
            "fname",
            "reminders",
            "reminder",
            "remind",
            "review",
            "reviewFiles",
            //"users",
            "email",
            "daysBefore",
            "name",

            "parent_ID",
            "parent",

            "extension",
            "paymentOnAccount",
            "emailTo",
            "emailToTmp",
            "note",
            "notification",
            //"lastSaved",

            "period",
            "year",
            "submitted",
            "vatId",
            "submissionFiles",
            "deadlineMonths",
            "deadlineDays",
            "emailAdd",
            "submit_xml",
            "users",
            "matrix",
            "internalNote",
            "Cmp_key",
            "fileIDName",
        ];
        return [
            d.map((a) => {
                const key = a[0];

                if (f.general.country === "DE") {
                    skips.push("tnr1", "tnr2", "tnr3", "year", "period", "name", "prename", "lastname", "houseNumber", "county", "city", "hnr", "postcode", "street", "user_id", "finanzamt", "response", "body", "submit_file", "credentials")
                } else {
                    skips.push("county")
                }

                if (f.general.country === "GB") {
                    skips.push("request", "correlation_id", "response", "correlationId");
                    if (f.general.report === "vat_return") {
                        skips.push("year", "period", "due", "start", "end", "periodKey")
                    }
                }
                if (!f[key]) {
                    return null
                }

                if (typeof f[key] === "object" && !Array.isArray(f[key])) {
                    if (skips.indexOf(key) > -1) {
                        return null;
                    }
                    let a = Object.keys(f[key]).filter(k => skips.indexOf(k) === -1).map(key2 =>
                        <PrintRow k={key2} value={f[key][key2]} fId={f.id} colSpan={colSpan} colAll={colAll} report={f.general.report + "_" + f.general.country.toLowerCase()}/>
                    );
                    return a;
                } else {
                    if (skips.indexOf(key) >= 0) {
                        return null
                    }
                    return [<PrintRow k={key} value={f[key]} fileName={f[key + "Name"]} fId={f.id} colSpan={colSpan} colAll={colAll} report={f.general.report + "_" + f.general.country.toLowerCase()}/>]
                }
            }),
            !!this.props.filing.data?.company?.vatId && !this.props.filing.data?.general?.report?.includes("vat_registration") ?
                <tr>
                    <td colSpan={colSpan}>VAT ID</td>
                    <td colSpan={colAll - colSpan}><a target={"_blank"}
                                                      href={"https://essentia.vat-search.eu/search/" + this.props.filing.data.general.country + this.props.filing.data.company.vatId}>{this.props.filing.data.company.vatId}</a></td>
                </tr> : null,
            this.props.filing?.data?.specific?.deadlineDays ?
                <tr>
                    <td colSpan={colSpan}>Deadline</td>
                    <td colSpan={colAll - colSpan}>{this.props.filing?.data?.specific?.deadlineMonths || 0} month{this.props.filing?.data?.specific?.deadlineMonths !== "1" && "s"} and {this.props.filing?.data?.specific?.deadlineDays} days after period end</td>
                </tr>
                : null,
            (f.general.country !== "GB" || f.general.report !== "vat_return") ?
                [
                    f.specific !== undefined && f.specific.period !== undefined && f.specific.year !== undefined ? <tr>
                        <td colSpan={colSpan}>Period</td>
                        <td colSpan={colAll - colSpan}>{f.specific.year} - {DEPeriods[f.specific.period] ?? f.specific.period}</td>
                    </tr> : null,
                ] : null,
            (f.general.country === "DE" && !isManual(f)) ?
                [
                    /*f.specific !== undefined && f.specific.period !== undefined && f.specific.year !== undefined ? <tr>
                        <td colSpan={colSpan}>Period</td>
                        <td colSpan={colall - colSpan}>{f.specific.year} - {DEPeriods[f.specific.period] !== undefined ? DEPeriods[f.specific.period] : f.specific.period}</td>
                    </tr> : null,*/
                    f.company !== undefined/*&& f.company.prename !== undefined && f.company.lastname !== undefined  ({f.company.prename} {f.company.lastname})*/ ?
                        <tr>
                            <td colSpan={colSpan}>Name</td>
                            <td colSpan={colAll - colSpan}>{f.company.name}</td>
                        </tr> : null,
                    f.company !== undefined && f.company.street !== undefined && f.company.houseNumber !== undefined && f.company.city !== undefined && f.company.postcode !== undefined && f.company.country !== undefined ?
                        <tr>
                            <td colSpan={colSpan}>Address</td>
                            <td colSpan={colAll - colSpan}>{f.company.street} {f.company.houseNumber}, {f.company.postcode} {f.company.city}, {f.company.country}</td>
                        </tr> : null,
                    /*xmlDoc !== undefined ?
                        [<tr>
                            <td colSpan={colspan}>Transferticket</td>
                            <td colSpan={colall - colspan}>{xmlDoc.getElementsByTagName("TransferTicket")[0].innerHTML}</td>
                        </tr>,
                            <tr>
                                <td colSpan={colspan}>Return Text</td>
                                <td colSpan={colall - colspan}>
                                    {xmlDoc.querySelector("Rueckgabe Text").innerHTML !== undefined ? xmlDoc.querySelector("Rueckgabe Text").innerHTML.replace("Daten wurden erfolgreich angenommen.", "The data has been successfully accepted.") : ""}
                                </td>
                            </tr>
                        ]
                        : null*/
                ] : null,
            f.general.country === "GB" && f.specific !== undefined && f.specific.start !== undefined ?
                [
                    <tr>
                        <td colSpan={colSpan}>Period</td>
                        <td colSpan={colAll - colSpan}>{dateFormatString(f.specific.start) + " to " + dateFormatString(f.specific.end) /*(due by {dateFormatString(f.specific.due)})*/}</td>
                    </tr>
                ] : null,
            ((this.props.filing.GroupCompanyStatus === "inactive" || this.props.filing.GroupCompanyStatus === "suspended") ? <tr>
                <td colSpan={colSpan}>Account Status</td>
                <td colSpan={colAll - colSpan}>
                    {this.props.filing.GroupCompanyStatus}
                    {this.props.filing.GroupCompanyStatusReason ? ", " + this.props.filing.GroupCompanyStatusReason : ""}
                    {!!this.props.filing.GroupCompanyStatusDate && <>, {dateFormat(this.props.filing.GroupCompanyStatusDate * 1000)}</>}
                </td>
            </tr> : null),
            (!!f.submissionFiles && f.submissionFiles.length > 0) ?
                <tr>
                    <td colSpan={colSpan}>Submission Confirmation Files</td>
                    <td colSpan={colAll - colSpan}>
                        {
                            f.submissionFiles.map(file => {

                                const [usr_id, rest1] = file.path.split("/");
                                const [time, rest2] = (rest1 || "").split("-");
                                const [token, suffix] = (rest2 || "").split(".");
                                return <a href={Consts.f("//localhost:5000", "") + Consts.API_PREFIX + "/file/download/" + file.path + "?prettyName=" + (file.name || "")}>{file.name || (suffix || "").toUpperCase() + " file"}</a>
                            })
                        }
                    </td>
                </tr>
                :
                null,
            this.props.filing.duedate !== null ?
                <tr>
                    <td colSpan={colSpan}>Due Date</td>
                    <td colSpan={colAll - colSpan}>
                        {dateFormat(this.props.filing.duedate * 1000)}
                        &nbsp;
                        {this.props.filing.status === 0 && (this.props.filing.data.specific === undefined || !this.props.filing.data.specific.submitted) &&
                            <>({this.props.filing.duedateDays >= 0 && "in "}{Math.abs(this.props.filing.duedateDays)} day{Math.abs(this.props.filing.duedateDays) !== 1 && "s"}{this.props.filing.duedateDays < 0 && " ago"})</>
                        }
                        {f.specific.extension === 1 && ", " + ({NL: "Foreign Company Filing Extension", LU: "Simplified Annual"}[f.general.country] || "Filing Extension in place")}
                        {f.specific.paymentOnAccount === 1 && ", Payment on Account"}
                        {f.specific.paperfiling !== undefined && (", " + (f.specific.paperfiling ? "Filing on Paper" : "Filing Digitally"))}
                        {f.specific.ecsl !== undefined && (", " + (f.specific.ecsl ? "filing ECSL as well" : "no ECSL filing"))}
                    </td>
                </tr>
                : null,
            f.review?.users?.length > 0 && f.review?.users[0]?.length > 0 ?
                <tr>
                    <td colSpan={colSpan}>Review Process</td>
                    <td colSpan={colAll - colSpan}>
                        {
                            f.review.approved ? "approved" : (f.review.started ? "started" : "not started")
                        }
                    </td>
                </tr>
                : null,
            this.props.filing?.collaborators ?
                <tr>
                    <td colSpan={colSpan}>Collaborators</td>
                    <td colSpan={colAll - colSpan}>
                        {this.props.filing?.collaborators}
                    </td>
                </tr>
                : null,
            this.props.filing?.GroupCompanyCountryData ? <>
                    {
                        this.context?.user?.Role > 10 && this.props.filing?.GroupCompanyCountryData?.fileUploadNotification && <tr>
                            <td colSpan={colSpan}>Notify On Upload</td>
                            <td colSpan={colAll - colSpan}>
                                {this.props.filing?.GroupCompanyCountryData?.fileUploadNotification?.map(a=>a.label).join(", ")}
                            </td>
                        </tr>
                    }
                </>
                : null,
            (this.props.filing.status === 0 ?
                <tr>
                    <td colSpan={5}>
                        <InsertIfNonClient alternatively={<>
                            <IconLink to={`/filings/edit/${this.props.filing.ID}`} text={"view"} icon={<FaArrowCircleRight/>}/>
                            {
                                !this.props.filing.data.specific?.submitted &&
                                <IconLink to={`/company/${this.props.filing.Cmp_key}/0`} text={"upload data"} icon={<FaUpload/>}/>
                            }
                        </>}>
                            <IconLink to={`/filings/edit/${this.props.filing.ID}`} icon={<FaArrowCircleRight/>}>
                                edit & {this.props.filing.data.general.report.indexOf("manual") > -1 ? <>send</> : <>submit</>}
                            </IconLink>
                            <em onClick={() => window.confirm("Do you really want to delete this filing?") && this.delete()}>
                                delete <i className={"fa fa-trash"}/>
                            </em>
                            {
                                !!this.props.filing.data?.specific?.submitted && <IconLink to={{pathname: "/filings/new", search: "likeId=" + f.id}} icon={<FaArrowCircleRight/>}>do filing like that</IconLink>
                            }
                        </InsertIfNonClient>
                        <em onClick={() => this.apiGet(`/vat_return/download/${f.id}`, resp => {
                            downloadAsExcel(resp.file, `Filing ${f.id} ${this.props.filing.data?.company?.name} ${this.props.filing.data?.general?.country} ${this.props.filing.data?.general?.report}.xlsx`)
                        })}>
                            download as excel&nbsp;
                            <FaDownload/>
                        </em>
                        {
                            !this.state.byCompany &&
                            <IconLink to={"/company/" + this.props.filing.Cmp_key + "/0"} icon={<FaArrowCircleRight/>}>show all filings of this entity</IconLink>
                        }
                        {/*<Link to={`/filings/quickadd/${f.id}`} style={{marginLeft: "30px"}}>
                        quickedit <i className={"fa fa-arrow-right"}/>
                    </Link>*/}
                    </td>

                </tr>
                : <tr>
                    <td colSpan={4}>
                        <IconLink to={`/filings/edit/${this.props.filing.ID}`} icon={<FaArrowCircleRight/>}>view</IconLink>
                        <InsertIfNonClient>
                            <IconLink to={{pathname: "/filings/new", search: "likeId=" + f.id}} icon={<FaArrowCircleRight/>}>do filing like that</IconLink>
                        </InsertIfNonClient>
                        {
                            !this.state.byCompany &&
                            <IconLink to={"/company/" + this.props.filing.Cmp_key + "/0"} icon={<FaArrowCircleRight/>}>show all filings of this entity</IconLink>
                        }

                    </td>
                </tr>),


        ]

    }
}

function IconLink({to, text, icon, children}) {
    return <Link to={to} className={"iconLink"}> <span>{text || children}</span> {icon} </Link>
}

const isManual = (data) => {
    return data.general !== undefined && data.general.report.indexOf("manual") > -1
};

function PrintRow({report, value, k: key, colSpan, colAll, fileName, ...props}) {
    const userContext = useContext(UserContext);

    if (key === undefined) {
        return null;
    }
    //console.log(key, key)
    var keyPretty = key.replace(/_/, " ").replace(/([A-Z]+)/g, " $1").replace(/(^|\s)[a-z]/, v => v.toUpperCase()).replace(/Id/g, "ID");

    if (keyNames[key] !== undefined) {
        keyPretty = keyNames[key]
    }

    if (["time", "lastSaved", "submitTime",].includes(key)) {
        if (key === "submitTime" && !value) {
            return null;
        }
        //if (key === "lastSaved" && userContext?.user?.Role <= 10) return null
        return (<tr key={key}>
            <td colSpan={colSpan}>{keyPretty}</td>
            <td colSpan={colAll - colSpan}> {dateFormatTime(value * 1000)}</td>
        </tr>)
    } else if (["start", "end"].includes(key)) {
        return (<tr key={key}>
            <td colSpan={colSpan}>{keyPretty}</td>
            <td colSpan={colAll - colSpan}> {dateFormat(value * 1000)}</td>
        </tr>)
    } else if (key === "paperfiling" || key === "ecsl") {
        return null;
    } else if (key === "id") {
        return null;
    } else if ((key === "fileID" || key === "response_file" || key === "submit_file" || key === "testSubmit" || key === "submissionFile")) {
        if (props.fId === "" || props.fId === undefined || value === "") {
            return "asdf"
        }
        return <tr key={key}>
            <td colSpan={colSpan}>{keyPretty}</td>
            <td colSpan={colAll - colSpan}>
                <a href={Consts.API_PREFIX + "/file/download/" + value}>{fileName || <>download {value !== "" && "(" + value.split("|")[0].split(".")[1] + ")"}</>}</a>
                {
                    key === "response_file" && report != "vat_return_gb"  &&
                    <a href={Consts.API_PREFIX + "/vat_return/" + report.substr(-2, 2) + "/summary/" + props.fId + ".pdf"}> summary and payment instructions (pdf)</a>
                }
            </td>
        </tr> // {value != "" && value.split("|")[0]}
    } else if (key === "inputFiles") {
        return <tr key={key}>
            <td colSpan={colSpan}>{keyPretty}</td>
            <td colSpan={colAll - colSpan}>
                {
                    value.map(file=><div><a href={Consts.API_PREFIX + "/file/download/" + file.path}>{file.name}</a></div>)
                }
            </td>
        </tr>
    } else if (key === "credentials") {
        return (<tr key={key}>
            <td colSpan={colSpan}>{keyPretty}</td>
            <td colSpan={colAll - colSpan}> {value !== "" && value.split("|")[0]}</td>
        </tr>)
    } else if (["notRequiredToSubmit", "directDebitSetUp", "nil","fiscalRepresentative","chargeable","submittedForApproval"].includes(key)) {
        return (<tr key={key}>
            <td colSpan={colSpan}>{keyPretty}</td>
            <td colSpan={colAll - colSpan}> {value == 1 ? "yes" : "no"}</td>
        </tr>)
    } else if (key === "taxnr") {
        //console.log("ljsdljf", value);
        return [<tr key={key}>
            <td colSpan={colSpan}>{keyPretty} </td>
            <td colSpan={colAll - colSpan}>{extractSteuernummer(value)}
                &nbsp;
                (<Link style={{marginRight: 0}} to={"/finanzamt/" + value.substr(0, 4)}>{id2finanzamt[value.substr(0, 4)]}</Link>)
            </td>
        </tr>]
    } else if (key === "paymentIndicator") {
        //console.log("ljsdljf", value);
        return (<tr key={key}>
            <td colSpan={colSpan}>{keyPretty}</td>
            <td colSpan={colAll - colSpan}> {value !== "" && value.replace("DD", "Direct Debit", "")}</td>
        </tr>)
    } else if (key === "internalNotes") {
        if (userContext?.user?.Role <= 10) return null
        //console.log("ljsdljf", value);
        return (<tr key={key}>
            <td colSpan={colSpan}>{keyPretty}</td>
            <td colSpan={colAll - colSpan}>
                <div dangerouslySetInnerHTML={{__html: value}}></div>
            </td>
        </tr>)
    } else if (key === "notifyOnUpload") {
        if (userContext?.user?.Role <= 10 || [Consts.ENV_DEMO, Consts.ENV_ESSENTIA, Consts.ENV_CFGI].includes(userContext.user?.EnvID)) {
            return null
        }
        //console.log("ljsdljf", value);
        return (<tr key={key}>
            <td colSpan={colSpan}>{keyPretty}</td>
            <td colSpan={colAll - colSpan}> {(value || []).map(a => a.label).join(", ")}
            </td>
        </tr>)
    } else if (key === "user") {
        return <tr key={key}>
            <td colSpan={colSpan}>{keyPretty}</td>
            <td colSpan={colAll - colSpan}>
                {value.split("|")[0]}
                {
                    userContext?.user?.Role > 10 && <> (<a style={{marginRight: 0}} href={"mailto:" + value.split("|")[1]}>{value.split("|")[1]}</a>)</>
                }
            </td>
        </tr>
    } else if (["accountManagers", "accountManagersBackup", "consultant", "clientRelationshipManager"].includes(key)) {
        if ([Consts.ENV_DEMO, Consts.ENV_ESSENTIA, Consts.ENV_CFGI].includes(userContext.user?.EnvID)) {
            return null;
        }
        return <tr key={key}>
            <td colSpan={colSpan}>{keyPretty}</td>
            <td colSpan={colAll - colSpan}>{value?.map(a => userContext?.user?.Role > 10 ? a.label : a.label.split(" (")[0]).join(", ")}</td>
        </tr>
    }
    return <tr key={key}>
        <td colSpan={colSpan}>{Consts.labels[key] || keyPretty}</td>
        <td colSpan={colAll - colSpan}> {value?.toString()}</td>
    </tr>
}


const keyNames = {
    ...Consts.labels,
    user: "Last Changed By",
    //name: "Company Name",//removed
    taxnr: "Tax Number",
};


export default FilingDetail;
