//console.log("PHASE=", PHASE)
class ConstsClass {
    f(dev, prod, user_id = -1) {
        if (process.env.NODE_ENV === "development" || (user_id !== -1 && this.user_id_prod.includes(user_id))) {
            return dev
        }
        return prod
    }

    constructor() {

        this.PHASE = process.env.NODE_ENV;
        this.user_id_prod = [4, 853, 870, 869, 868, 867, 861, 860, 859, 858, 857, 856];
        this.user_id_prod = [852, 2, 3, 1182, 1183, 1184,];//, 853, 870, 869, 868, 867, 861, 860, 859, 858, 857, 856];
        this.f = this.f.bind(this);

        let f = this.f;

        this.API_PREFIX = f("/api", "/api");

        this.GB_WEBSERVICE_HOST = (user_id = -1) => f("test-api.service.hmrc.gov.uk", "api.service.hmrc.gov.uk", user_id);
        this.GB_REDIR_URL = (user_id = -1) => f("http://localhost:3000", "https://vatomator.com", user_id);
        this.GB_CLIENT_ID = (user_id = -1) => f("b9OwUk6JKBMIrMUe8joUShYylFbN", "HFfhGQZ9jV7JMDQ1fVeO_6Nq5Ssa", user_id);

        this.GB_WEBSERVICE_HOST = this.GB_WEBSERVICE_HOST.bind(this);
        this.GB_REDIR_URL = this.GB_REDIR_URL.bind(this);
        this.GB_CLIENT_ID = this.GB_CLIENT_ID.bind(this);

        this.euCountries = [
            "AT",
            "BE",
            "BG",
            "CY",
            "CZ",
            "DK",
            "EE",
            "FI",
            "HR",
            "FR",
            "DE",
            "GR",
            "HU",
            "IE",
            "IT",
            "LV",
            "LT",
            "LU",
            "MT",
            "NL",
            "PL",
            "PT",
            "RO",
            "SK",
            "SI",
            "ES",
            "SE",
        ]


        this.countries = [
            /*
/**/

            ['AG', 'Aggregated'],


            ['AF', 'Afghanistan'],
            ['AX', 'Aland Islands'],
            ['AL', 'Albania'],
            ['DZ', 'Algeria'],
            ['AS', 'American Samoa'],
            ['AD', 'Andorra'],
            ['AO', 'Angola'],
            ['AI', 'Anguilla'],
            ['AQ', 'Antarctica'],
            ['AR', 'Argentina'],
            ['AM', 'Armenia'],
            ['AW', 'Aruba'],
            ['AU', 'Australia'],
            ['AT', 'Austria'],
            ['AZ', 'Azerbaijan'],
            ['BS', 'Bahamas'],
            ['BH', 'Bahrain'],
            ['BD', 'Bangladesh'],
            ['BB', 'Barbados'],
            ['BY', 'Belarus'],
            ['BE', 'Belgium'],
            ['BZ', 'Belize'],
            ['BJ', 'Benin'],
            ['BM', 'Bermuda'],
            ['BT', 'Bhutan'],
            ['BO', 'Bolivia'],
            ['BA', 'Bosnia And Herzegovina'],
            ['BW', 'Botswana'],
            ['BV', 'Bouvet Island'],
            ['BR', 'Brazil'],
            ['IO', 'British Indian Ocean Territory'],
            ['BN', 'Brunei Darussalam'],
            ['BG', 'Bulgaria'],
            ['BF', 'Burkina Faso'],
            ['BI', 'Burundi'],
            ['KH', 'Cambodia'],
            ['CM', 'Cameroon'],
            ['CA', 'Canada'],
            ['CV', 'Cape Verde'],
            ['KY', 'Cayman Islands'],
            ['CF', 'Central African Republic'],
            ['TD', 'Chad'],
            ['CL', 'Chile'],
            ['CN', 'China'],
            ['CX', 'Christmas Island'],
            ['CC', 'Cocos (Keeling) Islands'],
            ['CO', 'Colombia'],
            ['KM', 'Comoros'],
            ['CG', 'Congo'],
            ['CD', 'Congo, Democratic Republic'],
            ['CK', 'Cook Islands'],
            ['CR', 'Costa Rica'],
            ['CI', 'Cote D\'Ivoire'],
            ['HR', 'Croatia'],
            ['CU', 'Cuba'],
            ['CY', 'Cyprus'],
            ['CZ', 'Czech Republic'],
            ['DK', 'Denmark'],
            ['DJ', 'Djibouti'],
            ['DM', 'Dominica'],
            ['DO', 'Dominican Republic'],
            ['EC', 'Ecuador'],
            ['EG', 'Egypt'],
            ['SV', 'El Salvador'],
            ['GQ', 'Equatorial Guinea'],
            ['ER', 'Eritrea'],
            ['EE', 'Estonia'],
            ['ET', 'Ethiopia'],
            ['FK', 'Falkland Islands (Malvinas)'],
            ['FO', 'Faroe Islands'],
            ['FJ', 'Fiji'],
            ['FI', 'Finland'],
            ['FR', 'France'],
            ['GF', 'French Guiana'],
            ['PF', 'French Polynesia'],
            ['TF', 'French Southern Territories'],
            ['GA', 'Gabon'],
            ['GM', 'Gambia'],
            ['GE', 'Georgia'],
            ['DE', 'Germany'],
            ['GH', 'Ghana'],
            ['GI', 'Gibraltar'],
            ['GR', 'Greece'],
            ['GL', 'Greenland'],
            ['GD', 'Grenada'],
            ['GP', 'Guadeloupe'],
            ['GU', 'Guam'],
            ['GT', 'Guatemala'],
            ['GG', 'Guernsey'],
            ['GN', 'Guinea'],
            ['GW', 'Guinea-Bissau'],
            ['GY', 'Guyana'],
            ['HT', 'Haiti'],
            ['HM', 'Heard Island & Mcdonald Islands'],
            ['VA', 'Holy See (Vatican City State)'],
            ['HN', 'Honduras'],
            ['HK', 'Hong Kong'],
            ['HU', 'Hungary'],
            ['IS', 'Iceland'],
            ['IN', 'India'],
            ['ID', 'Indonesia'],
            ['IR', 'Iran, Islamic Republic Of'],
            ['IQ', 'Iraq'],
            ['IE', 'Ireland'],
            ['IM', 'Isle Of Man'],
            ['IL', 'Israel'],
            ['IT', 'Italy'],
            ['JM', 'Jamaica'],
            ['JP', 'Japan'],
            ['JE', 'Jersey'],
            ['JO', 'Jordan'],
            ['KZ', 'Kazakhstan'],
            ['KE', 'Kenya'],
            ['KI', 'Kiribati'],
            ['KR', 'Korea'],
            ['KW', 'Kuwait'],
            ['KG', 'Kyrgyzstan'],
            ['LA', 'Lao People\'s Democratic Republic'],
            ['LV', 'Latvia'],
            ['LB', 'Lebanon'],
            ['LS', 'Lesotho'],
            ['LR', 'Liberia'],
            ['LY', 'Libyan Arab Jamahiriya'],
            ['LI', 'Liechtenstein'],
            ['LT', 'Lithuania'],
            ['LU', 'Luxembourg'],
            ['MO', 'Macao'],
            ['MK', 'Macedonia'],
            ['MG', 'Madagascar'],
            ['MW', 'Malawi'],
            ['MY', 'Malaysia'],
            ['MV', 'Maldives'],
            ['ML', 'Mali'],
            ['MT', 'Malta'],
            ['MH', 'Marshall Islands'],
            ['MQ', 'Martinique'],
            ['MR', 'Mauritania'],
            ['MU', 'Mauritius'],
            ['YT', 'Mayotte'],
            ['MX', 'Mexico'],
            ['FM', 'Micronesia, Federated States Of'],
            ['MD', 'Moldova'],
            ['MC', 'Monaco'],
            ['MN', 'Mongolia'],
            ['ME', 'Montenegro'],
            ['MS', 'Montserrat'],
            ['MA', 'Morocco'],
            ['MZ', 'Mozambique'],
            ['MM', 'Myanmar'],
            ['NA', 'Namibia'],
            ['NR', 'Nauru'],
            ['NP', 'Nepal'],
            ['NL', 'Netherlands'],
            ['AN', 'Netherlands Antilles'],
            ['NC', 'New Caledonia'],
            ['NZ', 'New Zealand'],
            ['NI', 'Nicaragua'],
            ['NE', 'Niger'],
            ['NG', 'Nigeria'],
            ['NU', 'Niue'],
            ['NF', 'Norfolk Island'],
            ['MP', 'Northern Mariana Islands'],
            ['NO', 'Norway'],
            ['OM', 'Oman'],
            ['PK', 'Pakistan'],
            ['PW', 'Palau'],
            ['PS', 'Palestinian Territory'],
            ['PA', 'Panama'],
            ['PG', 'Papua New Guinea'],
            ['PY', 'Paraguay'],
            ['PE', 'Peru'],
            ['PH', 'Philippines'],
            ['PN', 'Pitcairn'],
            ['PL', 'Poland'],
            ['PT', 'Portugal'],
            ['PR', 'Puerto Rico'],
            ['QA', 'Qatar'],
            ['RE', 'Reunion'],
            ['RO', 'Romania'],
            ['RU', 'Russian Federation'],
            ['RW', 'Rwanda'],
            ['BL', 'Saint Barthelemy'],
            ['SH', 'Saint Helena'],
            ['KN', 'Saint Kitts And Nevis'],
            ['LC', 'Saint Lucia'],
            ['MF', 'Saint Martin'],
            ['PM', 'Saint Pierre And Miquelon'],
            ['VC', 'Saint Vincent And Grenadines'],
            ['WS', 'Samoa'],
            ['SM', 'San Marino'],
            ['ST', 'Sao Tome And Principe'],
            ['SA', 'Saudi Arabia'],
            ['SN', 'Senegal'],
            ['RS', 'Serbia'],
            ['SC', 'Seychelles'],
            ['SL', 'Sierra Leone'],
            ['SG', 'Singapore'],
            ['SK', 'Slovakia'],
            ['SI', 'Slovenia'],
            ['SB', 'Solomon Islands'],
            ['SO', 'Somalia'],
            ['ZA', 'South Africa'],
            ['GS', 'South Georgia And Sandwich Isl.'],
            ['ES', 'Spain'],
            ['LK', 'Sri Lanka'],
            ['SD', 'Sudan'],
            ['SR', 'Suriname'],
            ['SJ', 'Svalbard And Jan Mayen'],
            ['SZ', 'Swaziland'],
            ['SE', 'Sweden'],
            ['CH', 'Switzerland'],
            ['SY', 'Syrian Arab Republic'],
            ['TW', 'Taiwan'],
            ['TJ', 'Tajikistan'],
            ['TZ', 'Tanzania'],
            ['TH', 'Thailand'],
            ['TL', 'Timor-Leste'],
            ['TG', 'Togo'],
            ['TK', 'Tokelau'],
            ['TO', 'Tonga'],
            ['TT', 'Trinidad And Tobago'],
            ['TN', 'Tunisia'],
            ['TR', 'Turkey'],
            ['TM', 'Turkmenistan'],
            ['TC', 'Turks And Caicos Islands'],
            ['TV', 'Tuvalu'],
            ['UG', 'Uganda'],
            ['UA', 'Ukraine'],
            ['AE', 'United Arab Emirates'],
            ['GB', 'United Kingdom'],
            ['US', 'United States'],
            ['UM', 'United States Outlying Islands'],
            ['UY', 'Uruguay'],
            ['UZ', 'Uzbekistan'],
            ['VU', 'Vanuatu'],
            ['VE', 'Venezuela'],
            ['VN', 'Viet Nam'],
            ['VG', 'Virgin Islands, British'],
            ['VI', 'Virgin Islands, U.S.'],
            ['WF', 'Wallis And Futuna'],
            ['EH', 'Western Sahara'],
            ['YE', 'Yemen'],
            ['ZM', 'Zambia'],
            ['ZW', 'Zimbabwe'],
        ];
        this.countriesMap = {
            /*AT: 'Austria'
              BE: 'Belgium',
              BG: 'Bulgaria',
              CY: 'Cyprus',
              CZ: 'Czech Republic',
              DE: 'Germany',
              DK: 'Denmark',
              EE: 'Estonia',
              ES: 'Spain',
              FI: 'Finland',
              FR: 'France',
              GB: 'United Kingdom',
              GR: 'Greece',
              HU: 'Hungary',
              HR: 'Croatia',
              IE: 'Ireland',
              IT: 'Italy',
              LT: 'Lithuania',
              LU: 'Luxembourg',
              LV: 'Latvia',
              MT: 'Malta',
              NL: 'Netherlands',
              PL: 'Poland',
              PT: 'Portugal',
              RO: 'Romania',
              SE: 'Sweden',
              SI: 'Slovenia',
              SK: 'Slovakia',
  /**/

            AG: 'Aggregated',

            AF: 'Afghanistan',
            AX: 'Aland Islands',
            AL: 'Albania',
            DZ: 'Algeria',
            AS: 'American Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarctica',

            AR: 'Argentina',
            AM: 'Armenia',
            AW: 'Aruba',
            AU: 'Australia',
            AT: 'Austria',
            AZ: 'Azerbaijan',
            BS: 'Bahamas',
            BH: 'Bahrain',
            BD: 'Bangladesh',
            BB: 'Barbados',
            BY: 'Belarus',
            BE: 'Belgium',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Bhutan',
            BO: 'Bolivia',
            BA: 'Bosnia And Herzegovina',
            BW: 'Botswana',
            BV: 'Bouvet Island',
            BR: 'Brazil',
            IO: 'British Indian Ocean Territory',
            BN: 'Brunei Darussalam',
            BG: 'Bulgaria',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Cambodia',
            CM: 'Cameroon',
            CA: 'Canada',
            CV: 'Cape Verde',
            KY: 'Cayman Islands',
            CF: 'Central African Republic',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'China',
            CX: 'Christmas Island',
            CC: 'Cocos (Keeling) Islands',
            CO: 'Colombia',
            KM: 'Comoros',
            CG: 'Congo',
            CD: 'Congo, Democratic Republic',
            CK: 'Cook Islands',
            CR: 'Costa Rica',
            CI: 'Cote D\'Ivoire',
            HR: 'Croatia',
            CU: 'Cuba',
            CY: 'Cyprus',
            CZ: 'Czech Republic',
            DK: 'Denmark',
            DJ: 'Djibouti',
            DM: 'Dominica',
            DO: 'Dominican Republic',
            EC: 'Ecuador',
            EG: 'Egypt',
            SV: 'El Salvador',
            GQ: 'Equatorial Guinea',
            ER: 'Eritrea',
            EE: 'Estonia',
            ET: 'Ethiopia',
            FK: 'Falkland Islands (Malvinas)',
            FO: 'Faroe Islands',
            FJ: 'Fiji',
            FI: 'Finland',
            FR: 'France',
            GF: 'French Guiana',
            PF: 'French Polynesia',
            TF: 'French Southern Territories',
            GA: 'Gabon',
            GM: 'Gambia',
            GE: 'Georgia',
            DE: 'Germany',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Greece',
            GL: 'Greenland',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Heard Island & Mcdonald Islands',
            VA: 'Holy See (Vatican City State)',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Hungary',
            IS: 'Iceland',
            IN: 'India',
            ID: 'Indonesia',
            IR: 'Iran, Islamic Republic Of',
            IQ: 'Iraq',
            IE: 'Ireland',
            IM: 'Isle Of Man',
            IL: 'Israel',
            IT: 'Italy',
            JM: 'Jamaica',
            JP: 'Japan',
            JE: 'Jersey',
            JO: 'Jordan',
            KZ: 'Kazakhstan',
            KE: 'Kenya',
            KI: 'Kiribati',
            KR: 'Korea',
            KW: 'Kuwait',
            KG: 'Kyrgyzstan',
            LA: 'Lao People\'s Democratic Republic',
            LV: 'Latvia',
            LB: 'Lebanon',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Libyan Arab Jamahiriya',
            LI: 'Liechtenstein',
            LT: 'Lithuania',
            LU: 'Luxembourg',
            MO: 'Macao',
            MK: 'Macedonia',
            MG: 'Madagascar',
            MW: 'Malawi',
            MY: 'Malaysia',
            MV: 'Maldives',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshall Islands',
            MQ: 'Martinique',
            MR: 'Mauritania',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Mexico',
            FM: 'Micronesia, Federated States Of',
            MD: 'Moldova',
            MC: 'Monaco',
            MN: 'Mongolia',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Morocco',
            MZ: 'Mozambique',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Netherlands',
            AN: 'Netherlands Antilles',
            NC: 'New Caledonia',
            NZ: 'New Zealand',
            NI: 'Nicaragua',
            NE: 'Niger',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Norfolk Island',
            MP: 'Northern Mariana Islands',
            NO: 'Norway',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Palestinian Territory',
            PA: 'Panama',
            PG: 'Papua New Guinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Philippines',
            PN: 'Pitcairn',
            PL: 'Poland',
            PT: 'Portugal',
            PR: 'Puerto Rico',
            QA: 'Qatar',
            RE: 'Reunion',
            RO: 'Romania',
            RU: 'Russian Federation',
            RW: 'Rwanda',
            BL: 'Saint Barthelemy',
            SH: 'Saint Helena',
            KN: 'Saint Kitts And Nevis',
            LC: 'Saint Lucia',
            MF: 'Saint Martin',
            PM: 'Saint Pierre And Miquelon',
            VC: 'Saint Vincent And Grenadines',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tome And Principe',
            SA: 'Saudi Arabia',
            SN: 'Senegal',
            RS: 'Serbia',
            SC: 'Seychelles',
            SL: 'Sierra Leone',
            SG: 'Singapore',
            SK: 'Slovakia',
            SI: 'Slovenia',
            SB: 'Solomon Islands',
            SO: 'Somalia',
            ZA: 'South Africa',
            GS: 'South Georgia And Sandwich Isl.',
            ES: 'Spain',
            LK: 'Sri Lanka',
            SD: 'Sudan',
            SR: 'Suriname',
            SJ: 'Svalbard And Jan Mayen',
            SZ: 'Swaziland',
            SE: 'Sweden',
            CH: 'Switzerland',
            SY: 'Syrian Arab Republic',
            TW: 'Taiwan',
            TJ: 'Tajikistan',
            TZ: 'Tanzania',
            TH: 'Thailand',
            TL: 'Timor-Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad And Tobago',
            TN: 'Tunisia',
            TR: 'Turkey',
            TM: 'Turkmenistan',
            TC: 'Turks And Caicos Islands',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukraine',
            AE: 'United Arab Emirates',
            GB: 'United Kingdom',
            US: 'United States',
            UM: 'United States Outlying Islands',
            UY: 'Uruguay',
            UZ: 'Uzbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Viet Nam',
            VG: 'Virgin Islands, British',
            VI: 'Virgin Islands, U.S.',
            WF: 'Wallis And Futuna',
            EH: 'Western Sahara',
            YE: 'Yemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe',
            EU: "European Union",
            "US-IL": "USA - Illinois",
            "US-NC": "USA - North Carolina",
            "US-NJ": "USA - New Jersey",
            "US-UT": "USA - Utah",
            "US-WA": "USA - Washington",
            "US-TX": "USA - Texas",
            "CA-FE": "Canada - Federal",
            "CA-QC": "Canada - Quebec",
            "CA-BC": "Canada - British Columbia",
            "CA-MB": "Canada - Manitoba",
            "CA-SK": "Canada - Saskatchewan",
        };
        this.countriesMapReverse = Object.entries(this.countriesMap).reduce((obj, [cc, name]) => ({...obj, [name]: cc}), {})
        /*this.reportCountries = [
            "GB", "DE", "AT", "FR", "BE", "IT", "NL", "IE", "PL", "CH", "ES", "FI", "CZ", "SE", "NO", "CA", "HU", "SI", "SK", "SG", "DK", "EE", "LV", "LT", "MT", "PT", "GR", "RO", "BG", "HR", "TR", "KR", "AU", "RU",
            "CY", "AG", "LU", "NZ", "AE", "TW", "JP", "EU",
            "ZA","UA",
            "US-IL", "US-NC", "US-NJ", "US-UT", "US-WA", "US-TX",
            "CA-QC", "CA-MB", "CA-BC", "CA-SK",
            "AR","CL","CO","MA","MX","NG","SA",
        ].sort();*/

        this.reportsRaw = {

            "GB": ["VAT Return", "EC SL", "Manual VAT Return", "Manual EC SL", "Manual OSS", "Manual Non-EU-OSS", "Manual Intrastat", "Manual Plastic Packaging Tax Return", "Manual VAT Notice 723A"],
            "DE": ["VAT Return", "EC SL", "Manual VAT Return", "Manual EC SL", "Manual Intrastat", "Manual OSS", "Manual Non-EU-OSS", "Manual VAT Payment", "Manual Corporate Tax"],
            "AT": ["Manual VAT Return", "Manual EC SL", "Manual Intrastat", "Manual OSS", "Manual Non-EU-OSS"],
            "FR": ["VAT Return", "Manual VAT Return", "Manual EC SL", "Manual Intrastat", "Manual OSS", "Manual Non-EU-OSS"],

            "IT": ["Manual VAT Return", "Manual Intrastat", "Manual Esterometro", "Manual OSS", "Manual Non-EU-OSS", "Manual VAT Payment", "Manual VAT Payment With F24", "Manual VAT Refund"],
            "NL": ["VAT Return", "Manual VAT Return", "Manual EC SL", "Manual Intrastat/Arrivals", "Manual Intrastat/Dispatches", "Manual OSS", "Manual Non-EU-OSS", "Manual Statistical Survey", "Manual Corporate Tax", "Manual Annual Accounts"],
            "BE": ["Manual VAT Return", "Manual EC SL", "Manual Intrastat", "Manual B2B Sales List", "Manual OSS", "Manual Non-EU-OSS"],
            "IE": ["VAT Return", "Manual VAT Return", "Manual EC SL", "Manual Intrastat/Arrivals", "Manual Intrastat/Dispatches", "Manual OSS", "Manual Non-EU-OSS", "Manual Corporate Tax"],

            "PL": ["Manual VAT Return", "Manual EC SL", "Manual Intrastat/Arrivals", "Manual Intrastat/Dispatches", "Manual OSS", "Manual Non-EU-OSS", "Manual PIVA Licence"],
            "SE": ["Manual VAT Return", "Manual EC SL", "Manual Intrastat", "Manual OSS", "Manual Non-EU-OSS"],
            "FI": ["Manual VAT Return", "Manual EC SL", "Manual Intrastat", "Manual OSS", "Manual Non-EU-OSS"],
            "DK": ["Manual VAT Return", "Manual EC SL", "Manual Intrastat", "Manual OSS", "Manual Non-EU-OSS"],
            "ES": ["Manual VAT Return", "Manual EC SL", "Manual Intrastat/Arrivals", "Manual Intrastat/Dispatches", "Manual OSS", "Manual Non-EU-OSS", "Manual SII", "Manual Form 347"],
            "CZ": ["Manual VAT Return", "Manual Control Statement", "Manual EC SL", "Manual Intrastat", "Manual OSS", "Manual Non-EU-OSS"],

            "HU": ["Manual VAT Return", "Manual EC SL", "Manual Intrastat", "Manual OSS", "Manual Non-EU-OSS", "Manual Real Time Reporting"],
            "SI": ["Manual VAT Return", "Manual EC SL", "Manual Intrastat", "Manual OSS", "Manual Non-EU-OSS"],
            "SK": ["Manual VAT Return", "Manual Control Statement", "Manual EC SL", "Manual Intrastat", "Manual OSS", "Manual Non-EU-OSS"],

            "EE": ["Manual VAT Return", "Manual EC SL", "Manual Intrastat", "Manual OSS", "Manual Non-EU-OSS"],
            "LT": ["Manual VAT Return", "Manual EC SL", "Manual Intrastat", "Manual OSS", "Manual Non-EU-OSS", "Manual SAFT"],
            "LV": ["Manual VAT Return", "Manual EC SL", "Manual Intrastat", "Manual OSS", "Manual Non-EU-OSS"],
            "MT": ["Manual VAT Return", "Manual EC SL", "Manual Intrastat", "Manual OSS", "Manual Non-EU-OSS"],
            "PT": ["Manual VAT Return", "Manual EC SL", "Manual Intrastat", "Manual OSS", "Manual Non-EU-OSS", "Manual SAFT"],
            "GR": ["Manual VAT Return", "Manual EC SL", "Manual Intrastat", "Manual OSS", "Manual Non-EU-OSS"],
            "RO": ["Manual VAT Return", "Manual EC SL", "Manual Intrastat", "Manual OSS", "Manual Non-EU-OSS", "Manual 394", "Manual SAFT"],
            "BG": ["Manual VAT Return", "Manual EC SL", "Manual Intrastat", "Manual OSS", "Manual Non-EU-OSS"],
            "HR": ["Manual VAT Return", "Manual EC SL", "Manual Intrastat", "Manual OSS", "Manual Non-EU-OSS"],
            "CY": ["Manual VAT Return", "Manual Intrastat", "Manual OSS", "Manual Non-EU-OSS"],
            "AG": ["Manual VAT Return"],
            "LU": ["Manual VAT Return", "Manual Intrastat", "Manual OSS", "Manual Non-EU-OSS"],

            "CH": ["Manual VAT Return", "Manual 13th Directive"],
            "NO": ["Manual VAT Return", "Manual VOES"],
            "CA-FE": ["Manual GST"],
            "CA-QC": ["Manual QST"],
            "CA-MB": ["Manual PST"],
            "CA-BC": ["Manual PST"],
            "CA-SK": ["Manual PST"],
            "SG": ["Manual GST"],

            "TR": ["Manual VAT Return"],
            "KR": ["Manual VAT Return"],
            "RU": ["Manual VAT Return"],
            "AU": ["Manual GST"],
            "NZ": ["Manual GST"],
            "AE": ["Manual VAT Return", "Manual VAT Refund Directive"],
            "TW": ["Manual VAT Return"],
            "JP": ["Manual CT Return", "Manual CT Payment"],
            "ZA": ["Manual VAT Return"],
            "UA": ["Manual VAT Return"],
            "US-IL": ["Manual Sales Tax Return"],
            "US-NC": ["Manual Sales Tax Return"],
            "US-NJ": ["Manual Sales Tax Return"],
            "US-UT": ["Manual Sales Tax Return"],
            "US-WA": ["Manual Sales Tax Return"],
            "US-TX": ["Manual Sales Tax Return"],
            "AR": ["Manual VAT Return"],
            "CL": ["Manual VAT Return"],
            "CO": ["Manual VAT Return"],
            "MA": ["Manual VAT Return"],
            "MX": ["Manual VAT Return"],
            "NG": ["Manual VAT Return"],
            "SA": ["Manual VAT Return"],
            "IN": ["Manual VAT Return"],
            "KE": ["Manual VAT Return"],

            "EU": ["Manual IOSS Return"],
            "MC": ["Manual 13th Directive"],
            "IS": ["Manual VAT Return"],
        };
        this.reportCountries = Object.keys(this.reportsRaw).sort();

        this.reports = Object.entries(this.reportsRaw)
            .map(([key, reports]) => [key, [...reports, reports.some(a => a.includes("VAT")) ? "Manual VAT Registration" : "Manual Registration", ...(this.euCountries.includes(key) ? ["Manual VAT Refund Directive", "Manual 13th Directive"] : [])]])
            .reduce((obj, curr) => ({...obj, [curr[0]]: curr[1]}), {});

        this.reportCountriesSelect = this.reportCountries
            .filter(cc => this.reports[cc])
            .map(cc => [cc, this.countriesMap[cc]])
            .sort((a, b) => a[1] > b[1] ? 1 : -1)

        this.labels = {
            vatId: "VAT ID",
            taxNrAt: "Tax Number (9 digit)",
            fiscalRepresentative: "Fiscal Representative?",
            extension: "Foreign Company Filing Extension",
            paperfiling: "Filing On Paper?",
            deadlineMonths: "Deadline Months After Period End",
            deadlineDays: "Deadline Days After Period End",
            fileID: "Data for Submission",
            inputFiles: "Data from Client",
            notRequiredToSubmit: "Required to submit?",
            directDebitSetUp: "Direct Debit set up?",
            submitTime: "Submit Date",
            submissionFiles: "Submission Confirmation File",
            emailTo: "Email Submission Notification",
            emailAdd: "Submission Email Additional Text",
            test_submits: "Test Submissions",
        }
    }

    ENV_ESSENTIA = 2
    ENV_DEMO = 1
    ENV_CFGI = 17

}

const Consts = new ConstsClass();

export default Consts;
