import {Container, EditorInput, ListStringHuman, SelectField, TextfieldInput} from "../core/input_fields";
import React from "react";
import {FormContext} from "../core/form_context";
import {UserContext} from "../user/UserContext";
import Consts from "../core/consts";
import {Link} from "react-router-dom";

class Reminder extends React.Component {
    static contextType = FormContext;

    render() {
        const state = this.context.state
        return <Container name={"Reminders"}>
            {
                state.general.country === "DEfsdf" && <>
                    {/*<SelectField name={"Filing Extension"} tag={"reminding_duration"} demandSelect selectives={[["1m", "one month"], ["2m", "two months"], ["3m", "three months"]]}/>*/}

                    <label>
                            <span>
                                Filing Extension
                        </span>
                        <label className={"switch"}>
                            <input type="checkbox" name={"reminding_extended"} checked={
                                state.reminding !== undefined && state.reminding.extended !== undefined && state.reminding.extended === 1 ? "checked" : ""
                            }/>
                            <span className="slider"/>
                        </label>
                    </label>
                    <br/>
                </>

            }
            <br/>
            <UserContext.Consumer>
                {
                    userContext => [Consts.ENV_ESSENTIA, Consts.ENV_DEMO, Consts.ENV_CFGI].includes(userContext.user.EnvID) ? <>
                        {
                            state.GroupCompany?.ID && <>
                                Send Reminders To:&nbsp;
                                {
                                    state.GroupCompanyContacts?.filter(a => a.sendReminders).map(a => a.emailAddress).join(", ")
                                } (edit on <Link to={"/groupcompanies/" + state.GroupCompany?.ID}>Companies Page</Link>)
                            </>
                        }
                    </>:<>
                        {
                            state.reminders !== undefined && state.reminders.length > 0 &&
                            <span>{state.reminders.map((r, i) => {
                                return <li key={"reminder" + i}>remind {r.email} {ListStringHuman(r.daysBefore.sort((a, b) => a - b))} days before the deadline&nbsp;
                                    <em onClick={() => {
                                        if (state.remind === undefined || state.remind.email === undefined || state.remind.email.length < 3 || state.remind.daysBefore === undefined) {
                                        } else {
                                            const add = [{email: state.remind.email.toLowerCase(), daysBefore: state.remind.daysBefore}];
                                            this.context.setState({
                                                    reminders:
                                                        state.reminders === undefined ?
                                                            add :
                                                            state.reminders.concat(add)
                                                    ,
                                                    remind: {
                                                        email: "",
                                                        daysBefore: [],
                                                        daysBeforeSelect: []
                                                    },
                                                    remindKey: state.reminders === undefined ? 1 : state.reminders.length + 1,
                                                },
                                            )
                                        }

                                        this.context.updateState("remind", "email", r.email.toLowerCase());
                                        this.context.updateState("remind", "daysBefore", r.daysBefore);
                                        this.context.updateState("remind", "daysBeforeSelect", r.daysBefore.map(d => {
                                            return {value: d, label: d + " days"}
                                        }));
                                        this.context.updateState("remindKey", "", i);
                                        this.context.setState(prevState => {
                                            return {
                                                reminders: prevState.reminders.filter((a, index) => index !== i)
                                            }
                                        })

                                    }}>edit</em>&nbsp;
                                    <em onClick={() => {
                                        this.context.setState(prevState => ({
                                            reminders: prevState.reminders.filter((a, index) => index !== i)
                                        }), this.props.checkForAutosave)
                                    }}>delete</em>
                                </li>
                            })}</span>
                        }


                        {/*<br/><hr/>*/}
                        <br/>
                        <TextfieldInput name={"Email"} tag={"remind_email"} type={"email"} ph={"john.doe@vatomator.com"} style={{width: "350px"}}/><br/>
                        <SelectField key={state.remindKey | 0} id={state.remindKey | 0} name={"Days Before"} tag={"remind_daysBefore"} type={"reactselect"} demandSelect multiple
                                     selectives={[1, 2, 3, 5, 7, 10, 14, 21, 28, 35, 42, 49, 56, 63, 70].map(d => {
                                         return {value: d, label: d + " days"}
                                     })}/><br/>
                        <em style={{cursor: "pointer", marginBottom: "20px"}} onClick={() => {
                            if (state.remind === undefined || state.remind.email === undefined || state.remind.email.length < 3 || state.remind.daysBefore === undefined) {
                                return
                            }
                            const add = [{email: state.remind.email.toLowerCase(), daysBefore: state.remind.daysBefore}];

                            this.context.setState({
                                    reminders:
                                        state.reminders === undefined ?
                                            add :
                                            state.reminders.concat(add)
                                    ,
                                    remind: {
                                        email: "",
                                        daysBefore: [],
                                        daysBeforeSelect: []
                                    },
                                    remindKey: state.reminders === undefined ? 1 : state.reminders.length + 1,
                                },
                                this.props.checkForAutosave
                            )
                        }

                        }
                        >
                            <i className={"fa fa-plus-circle"}/>&nbsp;
                            add
                        </em>
                    </>
                }
            </UserContext.Consumer>
            <Container name={"Reminding E-Mail Additional Text"} type={"sub"}>
                <EditorInput tag={"reminder_text"}/>
            </Container>


        </Container>
    }
}


export default Reminder
